import * as types from "./mutation-types";
import * as api from "../../utils/api";
import * as fhirHelper from "../../utils/fhir-helpers";
import * as appMutations from "../appMutations";
import * as appointmentMutations from "../appointments/mutation-types";

export const actions = {
  [types.PATIENTS_RESET]: async ({ commit }) => {
    commit(types.PATIENTS_RESET);
  },
  [types.LOAD_PATIENTS]: async ({ commit }, noCache) => {
    commit(types.LOAD_PATIENTS);
    try {
      const patients = await api.search("Patient", {}, noCache);
      if (patients?.data?.entry) {
        commit(
          types.LOAD_PATIENTS_SUCCESS,
          await Promise.all(
            patients.data.entry.map(async p => {
              let patient = fhirHelper.fhirToPatient(p.resource);
              return {
                ...patient,
              };
            })
          )
        );
      } else {
        commit(types.LOAD_PATIENTS_FAILURE, "no data");
      }
    } catch (error) {
      commit(types.LOAD_PATIENTS_FAILURE, error);
      throw error;
    }
  },
  [types.LOAD_PATIENT_DOCUMENTS]: async ({ commit, dispatch }, patientID) => {
    commit(types.LOAD_PATIENT_DOCUMENTS);
    try {
      var options = {
        headers: { "instance-id": localStorage.getItem("instanceID") },
        "Cache-Control": "no-cache",
      };

      let resp = await api.fetchDocumentReferences(patientID, options);
      const docs = resp?.data?.total > 0 ? resp.data?.entry.map(doc => fhirHelper.docRefToVM(doc.resource)) : [];

      commit(types.LOAD_PATIENT_DOCUMENTS_SUCCESS, docs);
    } catch (error) {
      commit(types.LOAD_PATIENT_DOCUMENTS_FAILURE, error);
      dispatch(appMutations.SNACKBAR_ERROR, "Error Loading Documents", {
        root: true,
      });
      throw error;
    }
  },
  [types.LOAD_PATIENT_BY_ID]: async ({ commit, dispatch }, { id, noCache }) => {
    commit(types.LOAD_PATIENT_BY_ID);
    try {
      const patientData = await api.fetchPatientDetails(id, noCache);
      if (!patientData) {
        commit(types.PATIENT_LOAD_NOTFOUND);
      } else {
        commit(types.PATIENT_LOAD_SUCCESS, patientData);
        commit("appointments/" + appointmentMutations.LOAD_PATIENT_APPOINTMENTS_COMPLETE, { appointments: patientData.appointments, practitioners: [] }, { root: true });
        dispatch(types.LOAD_PATIENT_DOCUMENTS, id);
      }
    } catch (error) {
      commit(types.PATIENT_LOAD_FAILURE, error);
      throw error;
    }
  },
  [types.SET_PATIENT]: async ({ commit, dispatch }, patient) => {
    dispatch(types.PATIENTS_RESET);
    commit(types.SET_PATIENT, patient);
  },
  [types.UPDATE_PATIENT]: async ({ commit, dispatch }, patient) => {
    commit(types.UPDATE_PATIENT);
    try {
      let options = {
        headers: { "instance-id": patient.instanceID },
      };
      let fhirPatient = fhirHelper.patientToFhir(patient);
      const resp = await api.updatePatient(fhirPatient, options);
      const patientResponse = fhirHelper.fhirToPatient(resp?.data);
      commit(types.UPDATE_PATIENT_SUCCESS, patientResponse);
      dispatch(appMutations.SNACKBAR_SUCCESS, "Patient Updated", {
        root: true,
      });
    } catch (error) {
      commit(types.UPDATE_PATIENT_FAILURE, error);
      dispatch(appMutations.SNACKBAR_ERROR, error, {
        root: true,
      });
    }
  },
  [types.SYNC_PATIENTS]: async ({ commit, dispatch }, { selectedPatients, integrationID }) => {
    commit(types.SYNC_PATIENTS);
    try {
      for (const patient of selectedPatients) {
        var fhirPatient = patient.fhirPatient;
        await api.post(`admin/integrations/${integrationID}/patient`, fhirPatient);
      }
      commit(types.SYNC_PATIENTS_SUCCESS);
      dispatch(appMutations.SNACKBAR_SUCCESS, "Patients Synced", {
        root: true,
      });
    } catch (error) {
      commit(types.SYNC_PATIENTS_FAILURE, "Error Syncing Patients");
      dispatch(appMutations.SNACKBAR_ERROR, error, {
        root: true,
      });
    }
  },
  [types.DELETE_PATIENTS]: async ({ commit, dispatch }, selectedPatients) => {
    commit(types.DELETE_PATIENTS);
    try {
      await api.executeBundle(fhirHelper.createDeleteBundle("Patient", selectedPatients));
      commit(types.DELETE_PATIENTS_SUCCESS);
      dispatch(appMutations.SNACKBAR_SUCCESS, "Patients have been queued for deletion.", {
        root: true,
      });
    } catch (error) {
      commit(types.DELETE_PATIENTS_FAILURE, error);
      dispatch(appMutations.SNACKBAR_ERROR, "Error Deleting Patients", {
        root: true,
      });
    }
  },
  [types.CREATE_DOCUMENT]: ({ commit }) => {
    commit(types.CREATE_DOCUMENT);
  },
  [types.CREATE_DOCUMENT_FAILURE]: ({ commit }) => {
    commit(types.CREATE_DOCUMENT_FAILURE);
  },
  [types.CREATE_DOCUMENT_COMPLETE]: ({ commit }, doc) => {
    commit(types.CREATE_DOCUMENT_COMPLETE, { docRef: fhirHelper.docRefToVM(doc) });
  },
  [types.DELETE_FILE]: async ({ commit, dispatch }, doc) => {
    try {
      let binaryUrl = doc.url ?? doc.content[0].attachment.url;
      if (binaryUrl.includes("Binary")) {
        await api.deleteResource(binaryUrl);
      }
      await api.deleteResource("DocumentReference/" + doc.id);
      commit(types.DELETE_FILE_SUCCESS, doc);
    } catch (error) {
      var errorMessage = "An error has occured. Please try again or contact support.";
      if (error.message.includes("401")) errorMessage = "Your session has expired. Please login to continue.";
      commit(types.DELETE_FILE_FAILURE, errorMessage);
      dispatch(appMutations.SNACKBAR_ERROR, errorMessage, {
        root: true,
      });
    }
  },
  [types.POPULATE_QUESTIONNAIRE]: async ({ commit, dispatch }, { patientID, form, responseID }) => {
    commit(types.POPULATE_QUESTIONNAIRE);
    try {
      var response;
      if (patientID) {
        response = await api.populateQuestionnaire(patientID, responseID, form.questionnaireID);
        response = fhirHelper.renderAnswers(response, form);
      } else response = fhirHelper.renderQuestionnaire(form);

      commit(types.POPULATE_QUESTIONNAIRE_SUCCESS, response);
    } catch (error) {
      var errorMessage = "An error has occured. Please try again or contact support.";
      if (error.message.includes("401")) errorMessage = "Your session has expired. Please login to continue.";
      commit(types.POPULATE_QUESTIONNAIRE_FAILURE, errorMessage);
      dispatch(appMutations.SNACKBAR_ERROR, errorMessage, {
        root: true,
      });
      throw error;
    }
  },
  [types.CREATE_QUESTIONNAIRERESPONSE]: async ({ commit, dispatch }, responses) => {
    commit(types.CREATE_QUESTIONNAIRERESPONSE, responses);

    try {
      var response = fhirHelper.mapResponse(responses);
      var result = await api.createResponse(response);

      commit(types.CREATE_QUESTIONNAIRERESPONSE_SUCCESS, result.data);
    } catch (error) {
      console.log(error);
      var errorMessage = "An error has occured. Please try again or contact support.";
      if (error.message.includes("401")) errorMessage = "Your session has expired. Please login to continue.";
      commit(types.CREATE_QUESTIONNAIRERESPONSE, errorMessage);
      dispatch(appMutations.SNACKBAR_ERROR, errorMessage, {
        root: true,
      });
    }
  },
  [types.UPDATE_QUESTIONNAIRERESPONSE]: async ({ commit, dispatch }, questionnaireResponse) => {
    commit(types.UPDATE_QUESTIONNAIRERESPONSE, questionnaireResponse);

    try {
      var response = fhirHelper.mapResponse(questionnaireResponse);
      var result = await api.put(`QuestionnaireResponse/${questionnaireResponse.id}`, response);

      commit(types.UPDATE_QUESTIONNAIRERESPONSE_SUCCESS, questionnaireResponse);
    } catch (error) {
      var errorMessage = "An error has occured. Please try again or contact support.";
      if (error.message.includes("401")) errorMessage = "Your session has expired. Please login to continue.";
      commit(types.UPDATE_QUESTIONNAIRERESPONSE_FAILURE, errorMessage);
      dispatch(appMutations.SNACKBAR_ERROR, errorMessage, {
        root: true,
      });
    }
  },
  [types.LOAD_QUESTIONNAIRERESPONSES]: async ({ commit, dispatch }, patientID) => {
    commit(types.LOAD_QUESTIONNAIRERESPONSES);
    try {
      var response = await api.search(`QuestionnaireResponse/?subject=Patient/${patientID}`, {});

      commit(types.LOAD_QUESTIONNAIRERESPONSES_SUCCESS, response?.data.entry);
    } catch (error) {
      console.log(error);
      var errorMessage = "An error has occured. Please try again or contact support.";
      if (error.message.includes("401")) errorMessage = "Your session has expired. Please login to continue.";
      commit(types.LOAD_QUESTIONNAIRERESPONSES_FAILURE, errorMessage);
      dispatch(appMutations.SNACKBAR_ERROR, errorMessage, {
        root: true,
      });
    }
  },
  [types.LOAD_RELATED_PERSONS]: async ({ commit, dispatch }, id) => {
    commit(types.LOAD_RELATED_PERSONS);
    try {
      const response = await api.search(`RelatedPerson/?identifier=${id}`, {});
      let persons = response?.data?.entry ? response?.data.entry.filter(entry => entry.resource.resourceType === "RelatedPerson") : [];

      commit(types.LOAD_RELATED_PERSONS_SUCCESS, persons);
    } catch (error) {
      commit(types.LOAD_RELATED_PERSONS, "Error loading data.");
      throw error;
    }
  },
};
