<template>
  <v-hover>
    <template #default="{ isHovering, props }">
      <v-card v-if="value" class="demographics-card mb-4" variant="elevated" v-bind="props">
        <v-card-title class="text-subtitle-2" style="height: 48px">
          <template v-if="!edit">Identifiers</template>
          <v-fade-transition>
            <span v-if="edit || (canEdit && isHovering)" class="float-right">
              <v-btn v-if="!edit" class="ma-0" size="small" icon variant="plain" color="on-surface" @click.stop="edit = true">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>

              <v-btn v-if="edit" variant="text" size="small" @click.stop="resetForm()">Cancel</v-btn>
              <v-btn v-if="edit" variant="elevated" color="secondary" size="small" class="mx-2" @click.stop="addIdentifier()">
                <v-icon>mdi-plus</v-icon>
                Add
              </v-btn>
              <v-btn v-if="edit" class="ml-1" variant="flat" color="primary" :loading="processing" size="small" @click.stop="save()">Save</v-btn>
            </span>
          </v-fade-transition>
        </v-card-title>
        <v-card-text class="px-4">
          <v-form v-if="value" ref="form" v-model="valid" class="px-0">
            <v-list-item v-for="(id, i) in value" :key="`id-${i}`" class="px-2" variant="plain" density="compact" :ripple="false" @click.once="clickId(id.value)">
              <template #title>
                <v-text-field v-if="edit" v-model="id.system" placeholder="System" density="compact" :disabled="!edit || disabled" :variant="!edit ? 'plain' : 'plain'" hide-details class="identifier-prop" width="100%"></v-text-field>
                <v-text-field v-model="id.value" :label="!edit ? id.system : ''" placeholder="Value" :density="edit ? 'compact' : 'default'" :disabled="!edit || disabled" hide-details :variant="!edit ? 'plain' : 'plain'" class="identifier-prop" width="100%" @click="clickId(id.value)"></v-text-field>
              </template>
              <template #append>
                <v-btn v-if="edit" variant="text" size="small" icon class="id-delete float-right mt-2" @click.stop="removeIdentifier(i)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
            </v-list-item>
          </v-form>
          <span v-else>No identifiers</span>
        </v-card-text>
      </v-card>
    </template>
  </v-hover>
  <v-snackbar v-model="snackbar" vertical>
    <div class="text-subtitle-1 pb-2">Copied to clipboard</div>
  </v-snackbar>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  value: {
    type: Array,
    required: true,
  },
  canEdit: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update"]);

// Define reactive variables
const value = ref(props.value);
const edit = ref(false);
const valid = ref(false);
const processing = ref(false);
const snackbar = ref(false);

// Watch for changes in the value prop and update the reactive variable
watch(
  () => props.value,
  newValue => {
    value.value = newValue;
  }
);

// Emit the updated value when the v-model is updated

const addIdentifier = () => {
  value.value.push({
    system: "",
    value: "",
  });
};

const removeIdentifier = index => {
  value.value.splice(index, 1);
};

const resetForm = () => {
  value.value = JSON.parse(JSON.stringify(props.value.filter(id => id.value)));
  edit.value = false;
  valid.value = false;
  processing.value = false;
};

const save = () => {
  processing.value = true;
  emit("update", value.value);
  edit.value = false;
  valid.value = true;
  processing.value = false;
};
const clickId = id => {
  if (edit.value) return;
  copyToClipboard(id);
  snackbar.value = true;
};
</script>
<style scoped>
.identifier-prop {
  width: 50%;
  text-align: center;
  float: left;
}
</style>
