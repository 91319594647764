import axios from "axios";
import * as fhirHelper from "./fhir-helpers";
import * as authHelper from "./auth-helper";
import { rateLimiter } from "./rateLimiter";

const CancelToken = axios.CancelToken;
let cancel;

const client = axios.create({
  baseURL: "/api",
  json: true,
  timeout: 1200000,
  cancelToken: new CancelToken(function executor(c) {
    cancel = c;
  }),
});

rateLimiter(client, { maxConcurrent: 10 });

client.interceptors.request.use(config => {
  var session = authHelper.getUserSession();
  if (session) {
    config.headers["Authorization"] = "Bearer " + session.idToken.jwtToken;
  }
  return config;
});

client.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    if (error.response && error.response.status === 401) {
      cancel('Operation canceled due to unauthorized request');
      window.location.href = "/userlogin?r=" + Math.random();
      return null;
    }
    return Promise.reject(error);
  }
);

var orgs;

async function GetInit() {
  return client.get("/GetInit");
}

async function fetchPatient(patientID) {
  return client.get("/patient/" + patientID);
}

async function mapPatientResponse(response) {
  if (!response?.data) return null;
  let patients = response?.data.entry ? response?.data.entry.filter(entry => entry.resource.resourceType === "Patient") : [];
  let provenances = response?.data.entry ? response?.data.entry.filter(entry => entry.resource.resourceType === "Provenance") : [];
  let responses = response?.data.entry ? response?.data.entry.filter(entry => entry.resource.resourceType === "QuestionnaireResponse") : [];
  let appointments = response?.data?.entry ? response?.data.entry.filter(entry => entry.resource.resourceType === "Appointment") : [];

  if (patients.length === 0) return null;

  patients = await Promise.all(
    patients.map(async p => {
      let patient = fhirHelper.fhirToPatient(p.resource);
      let prov = provenances.find(p => p.resource.target[0].reference === `Patient/${patient.id}`);
      patient.created = prov ? prov.resource.recorded : null;
      if (prov) {
        const roleCode = prov.resource.agent?.[0].type.coding[0].code;
        patient.createdBy = roleCode.toLowerCase() === "patient" ? "Self" : roleCode.toLowerCase() === "proxy" ? "Proxy" : "Admin";
        patient.createdByRef = prov.resource.agent?.[0].who.reference;
      }

      return {
        patient: { ...patient },
        responses: responses,
        appointments: appointments,
        relatedPersons: await Promise.all(response?.data?.entry?.filter(entry => entry.resource.resourceType === "RelatedPerson").map(e => e.resource)),
      };
    })
  );
  return patients[0];
}


async function fetchPatientDetails(id, noCache) {
  let headers = {};
  if (noCache === true) headers["Cache-Control"] = "no-cache";
  const response = await client.get(
    "/patient/?_id=" + id + "&_revinclude=Provenance:target&_revinclude=QuestionnaireResponse:subject:target&_revinclude=Appointment:patient:target&_revinclude=RelatedPerson:patient:target",
    { headers: headers }
  );
  if (!response) return {};
  return mapPatientResponse(response);
}

async function createPatient(patient, options) {
  return client.post("/patient", patient, options);
}

async function updatePatient(patient, options) {
  return client.put("/patient", patient, options);
}

async function executeBundle(bundle, immediate) {
  return client.post(`Batch/?immediate=${immediate ? true : false}`, bundle);
}

async function createResponse(questionnaireResponse, options) {
  return client.post("/QuestionnaireResponse", questionnaireResponse, options);
}

async function createBinary(file, options) {
  return client.post("/Binary", file, options);
}

async function fetchOrganizations() {
  if (orgs) return orgs;
  const response = await client.get("/Organization");
  orgs = response?.data;
  return response?.data;
}

async function fetchDocumentReferences(patient, options) {
  var params = patient ? "?patient=" + patient : "";
  return client.get("/DocumentReference" + params, options);
}

async function fetchQuestionnaireResponses(patient) {
  return client.get("/QuestionnaireResponse?subject=" + patient);
}
async function populateQuestionnaire(patientID, responseID, id) {
  const response = await client.post("/Questionnaire/$populate", {
    parameter: [
      {
        name: "subject",
        valueString: `Patient/${patientID}`,
      },
      {
        name: "questionnaireRef",
        valueString: `Questionnaire/${id}`,
      },
      {
        name: "questionnaireResponseRef",
        valueString: `${responseID}`,
      },
    ],
  });
  return response?.data;
}

async function downloadFile(doc, options) {
  const response = await client.get(doc.url, {
    responseType: "blob",
    ...options,
  });
  const downloadUrl = window.URL.createObjectURL(new Blob([response?.data]));
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.setAttribute("download", doc.name); //any other extension
  document.body.appendChild(link);
  link.click();
  link.remove();
}

async function createAppointment(appointment, options) {
  return client.post("/Appointment", appointment, options);
}

async function fetchAppointments(patient, overrideOptions) {
  const options = overrideOptions ? overrideOptions : {};
  const apptOptions = patient ? { ...options, params: { patient: patient } } : options;
  return client.get("/Appointment", apptOptions);
}

async function post(resourceType, resource, overrideOptions) {
  const options = overrideOptions ? overrideOptions : {};
  const response = await client.post(resourceType, resource, options);
  return response?.data;
}
async function deleteResource(ref, object) {
  var config = {
    data: object ? object : null
  };
  return client.delete(ref, config).catch(function (error) {
    throw new Error(error?.response?.data);
  });
}

async function get(url, noCache) {
  var headers = {};
  if (noCache === true) headers["Cache-Control"] = "no-cache";
  else headers["Cache-Control"] = "";
  return client.get(url, { headers: headers }).catch(function (error) {
    if (error.response) {
      throw error.response?.data;
    } else if (error.request) {
      throw error.request.response;
    } else {
      throw error.message;
    }
  });
}

async function search(resource, { params, signal }, noCache) {
  var headers = {};
  if (noCache === true) headers["Cache-Control"] = "no-cache";
  else headers["Cache-Control"] = "";
  return client.get(resource, { params: params, headers, signal });
}

async function put(resourceType, resource, overrideOptions) {
  const options = overrideOptions ? overrideOptions : {};
  const response = await client.put("/" + resourceType, resource, options);
  return response?.data;
}

async function randomPatient() {
  var patient = {},
    appointment = {};

  const resp = await get("admin/fake?resourceType=Patient");
  const data = await resp.data;
  patient = data;
  patient.password = resp.headers["password"];

  return { patient: patient, appointment: appointment };
}

export {
  client,
  GetInit,
  fetchPatient,
  fetchPatientDetails,
  createPatient,
  updatePatient,
  createResponse,
  createBinary,
  fetchOrganizations,
  fetchDocumentReferences,
  fetchQuestionnaireResponses,
  populateQuestionnaire,
  downloadFile,
  createAppointment,
  fetchAppointments,
  executeBundle,
  post,
  deleteResource,
  search,
  get,
  put,
  randomPatient
};
