const invertKeyValues = (obj, fn) =>
  Object.keys(obj).reduce((acc, key) => {
    const val = fn ? fn(obj[key]) : obj[key];
    acc[val] = acc[val] || [];
    acc[val].push(key);
    return acc;
  }, {});

function getCachedObject(name) {
  var object = localStorage.getItem(name);
  if (object) {
    object = JSON.parse(object.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t").replace(/\f/g, "\\f"));
  }
  return object;
}

var colors = [
  { name: "red", text: "white", hex: "#F44336" },
  { name: "pink", text: "white", hex: "#E91E63" },
  { name: "purple", text: "white", hex: "#9C27B0" },
  { name: "deep-purple", text: "white", hex: "#673AB7" },
  { name: "indigo", text: "white", hex: "#3F51B5" },
  { name: "blue", text: "white", hex: "#2196F3" },
  { name: "light-blue", text: "white", hex: "#03A9F4" },
  { name: "cyan", text: "white", hex: "#00BCD4" },
  { name: "teal", text: "white", hex: "#009688" },
  { name: "green", text: "white", hex: "#4CAF50" },
  { name: "grey", text: "white", hex: "#9E9E9E" },
  // { name: "lime", text: "white", hex: "#8BC34A" },
  { name: "blue", text: "white", hex: "#2196F3" },
  { name: "amber", text: "white", hex: "#FFC107" },
  { name: "orange", text: "white", hex: "#FF9800" },
  { name: "light-blue-darken-4", text: "white", hex: "#01579B" },
  { name: "brown", text: "white", hex: "#795548" },
  { name: "blue-grey", text: "white", hex: "#607D8B" },
  { name: "grey", text: "white", hex: "#9E9E9E" },
];

function colorFromString(string) {
  if (!string) return { name: "blue", text: "white", hex: "#2196F3" };
  if (string.toString().toLowerCase()?.startsWith("high")) return { name: "red", text: "white", hex: "#F44336" };
  var index = string ? string.toString().toLowerCase().trim().charCodeAt(0) - 65 : 0;

  while (index > colors.length - 1) {
    index -= colors.length;
  }
  return colors[index] ?? { name: "blue", text: "white", hex: "#2196F3" };
}

function checkEmail(value) {
  if (!value) return true;
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return pattern.test(value) || "Invalid e-mail.";
}

function debounce(fn, delay = 500) {
  var timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    var args = arguments;
    var that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}
function computeHash(val) {
  if (typeof val !== "string") val = JSON.stringify(val);
  var hash = 0;
  if (!val || val?.length === 0) {
    return hash;
  }
  for (var i = 0; i < val.length; i++) {
    var char = val.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}
function formatShortDate(val) {
  if (!val) return null;
  try {
    let date = new Date(val);
    if (val.indexOf("T0") < 0 && val.indexOf(":00") < 0 && val.indexOf("Z") < 0) date = new Date(`${val}T00:00:00`);

    return `${date.getMonth() + 1}/${date.getDate()}`;
  } catch {
    return null;
  }
}
function formatDate(val) {
  if (!val) return null;
  try {
    val = val?.value ?? val;
    if (!val) return null;
    if (typeof val === "string") {
      val = val.split("T")[0]; // Remove time part to ensure date is parsed as local time
      //add local timezone offset
      val = `${val}T00:00:00`;
    }
    let date = new Date(val);
    if (isNaN(date.getMonth())) return null;
    return date.toLocaleDateString();
  } catch (e) {
    console.log(e);
    return null;
  }
}

function daysSince(val) {
  if (!val) return null;
  try {
    let date = new Date(val);
    if (isNaN(date.getMonth())) return null;

    //find how many days since the date
    return Math.floor((new Date() - date) / (1000 * 60 * 60 * 24));
  } catch {
    return null;
  }
}

function formatUTCDate(val) {
  if (!val) return null;
  try {
    let date = new Date(val);
    if (val.indexOf("T0") < 0 && val.indexOf(":00") < 0 && val.indexOf("Z") < 0) date = new Date(`${val}T00:00:00`);

    //convert to UTC and format
    return `${date.getUTCMonth() + 1}/${date.getUTCDate()}`;
  } catch {
    return null;
  }
}

function formatUTCDateTime(val) {
  if (!val) return null;

  //convert to local time and format
  try {
    val = val?.value ?? val;
    if (!val) return null;
    let date = new Date(val);
    if (isNaN(date.getMonth())) return null;
    return date.toLocaleString();
  } catch (e) {
    console.log(e);
    return null;
  }
}

function formatDateTime(dateVal) {
  if (!dateVal) return null;
  try {
    dateVal = dateVal?.value ?? dateVal;
    if (typeof dateVal === "string") {
      const [datePart, timePart] = dateVal.split("T");
      if (timePart) {
        dateVal = `${datePart}T${timePart.split("Z")[0]}`; // Remove "Z" to ensure date is parsed as local time
      }
    }
    let date = new Date(dateVal);

    let hours = date.getHours();
    let min = date.getMinutes();
    if (min < 10) {
      min = `0${min}`;
    }
    let ampm = "AM";
    if (hours > 12) {
      hours -= 12;
      ampm = "PM";
    } else if (hours === 12) {
      ampm = "PM";
    } else if (hours === 0) {
      hours = 12;
    }

    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${hours}:${min}${ampm}`;
  } catch {
    return null;
  }
}
function friendlyTime(val) {
  if (!val) return "-";
  const date = new Date(val);
  const delta = Math.round((Number(new Date()) - date) / 1000);

  const minute = 60,
    hour = minute * 60,
    day = hour * 24,
    week = day * 7;

  let fuzzy = val;
  if (delta < 30) {
    fuzzy = "just now";
  } else if (delta < minute) {
    fuzzy = `${delta} seconds ago`;
  } else if (delta < 2 * minute) {
    fuzzy = "a minute ago";
  } else if (delta < hour) {
    fuzzy = `${Math.floor(delta / minute)} minutes ago`;
  } else if (Math.floor(delta / hour) === 1) {
    fuzzy = "1 hour ago";
  } else if (delta < day) {
    fuzzy = `${Math.floor(delta / hour)} hours ago`;
  } else if (delta < day * 2) {
    fuzzy = "yesterday";
  } else if (delta <= week) {
    fuzzy = `${Math.floor(delta / day)} days ago`;
  } else if (delta <= week * 2) {
    fuzzy = "1 week ago";
  } else if (delta > week * 2 && delta < week * 52) {
    fuzzy = `${Math.floor(delta / week)} weeks ago`;
  } else if (delta > week * 52) {
    fuzzy = "Over a year ago";
  }

  return fuzzy;
}
function colorClassFromString(value) {
  if (!value || !colorFromString(value)) return null;
  const color = colorFromString(value);
  return `${color.name} text-${color.text}`;
}
function toProperCase(val) {
  return val?.replace(/\w\S*/g, txt => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
const countryCodes = [
  "+1",
  "+7",
  "+20",
  "+27",
  "+30",
  "+31",
  "+32",
  "+33",
  "+34",
  "+36",
  "+39",
  "+40",
  "+41",
  "+43",
  "+44",
  "+45",
  "+46",
  "+47",
  "+48",
  "+49",
  "+51",
  "+52",
  "+53",
  "+54",
  "+55",
  "+56",
  "+57",
  "+58",
  "+60",
  "+61",
  "+62",
  "+63",
  "+64",
  "+65",
  "+66",
  "+81",
  "+82",
  "+84",
  "+86",
  "+90",
  "+91",
  "+92",
  "+93",
  "+94",
  "+95",
  "+98",
  "+212",
  "+213",
  "+216",
  "+218",
  "+220",
  "+221",
  "+222",
  "+223",
  "+224",
  "+225",
  "+226",
  "+227",
  "+228",
  "+229",
  "+230",
  "+231",
  "+232",
  "+233",
  "+234",
  "+235",
  "+236",
  "+237",
  "+238",
  "+239",
  "+240",
  "+241",
  "+242",
  "+243",
  "+244",
  "+245",
  "+246",
  "+248",
  "+249",
  "+250",
  "+251",
  "+252",
  "+253",
  "+254",
  "+255",
  "+256",
  "+257",
  "+258",
  "+260",
  "+261",
  "+262",
  "+263",
  "+264",
  "+265",
  "+266",
  "+267",
  "+268",
  "+269",
  "+290",
  "+291",
  "+297",
  "+298",
  "+299",
  "+345",
  "+350",
  "+351",
  "+352",
  "+353",
  "+354",
  "+355",
  "+356",
  "+357",
  "+358",
  "+359",
  "+370",
  "+371",
  "+372",
  "+373",
  "+374",
  "+375",
  "+376",
  "+377",
  "+378",
  "+379",
  "+380",
  "+381",
  "+382",
  "+385",
  "+386",
  "+387",
  "+389",
  "+420",
  "+421",
  "+423",
  "+500",
  "+501",
  "+502",
  "+503",
  "+504",
  "+505",
  "+506",
  "+507",
  "+508",
  "+509",
  "+537",
  "+590",
  "+591",
  "+593",
  "+594",
  "+595",
  "+596",
  "+597",
  "+598",
  "+599",
  "+670",
  "+672",
  "+673",
  "+674",
  "+675",
  "+676",
  "+677",
  "+678",
  "+679",
  "+680",
  "+681",
  "+682",
  "+683",
  "+685",
  "+686",
  "+687",
  "+688",
  "+689",
  "+690",
  "+691",
  "+692",
  "+850",
  "+852",
  "+853",
  "+855",
  "+856",
  "+872",
  "+880",
  "+886",
  "+960",
  "+961",
  "+962",
  "+963",
  "+964",
  "+965",
  "+966",
  "+967",
  "+968",
  "+970",
  "+971",
  "+972",
  "+973",
  "+974",
  "+975",
  "+976",
  "+977",
  "+992",
  "+993",
  "+994",
  "+995",
  "+996",
  "+998",
];
function formatLongInt(val) {
  if (val === 0 || !val) return 0;
  if (!val) return null;
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
function removeFromArray(array, item) {
  const index = array.indexOf(item);
  if (index > -1) {
    array.splice(index, 1);
  }
}
function copyToClipboard(text) {
  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard.writeText(text)
      .then(() => {
        console.log("Text copied to clipboard");
      })
      .catch((error) => {
        console.error("Failed to copy text to clipboard:", error);
        document.execCommand("copy");
      });
  } else {
    const el = document.createElement("textarea");
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  }
}
export { copyToClipboard, formatUTCDate, daysSince, formatLongInt, removeFromArray, invertKeyValues, getCachedObject, colors, countryCodes, toProperCase, formatShortDate, formatDate, formatDateTime, colorFromString, colorClassFromString, checkEmail, debounce, computeHash, friendlyTime, formatUTCDateTime };
