import * as types from "./mutation-types";

import { getCachedUser } from "../../utils/auth-helper";

import { actions } from "./actions";

const state = {
  user: getCachedUser(),
  status: "",
  error: null,
  hasLoadedOnce: false,

  userAttributes: null,
  cognitoUser: null,
};

const getters = {
  isAuthenticated: state => !!state.user,
  authStatus: state => state.status,
  getUser: state => state.user,
};

const mutations = {
  [types.AUTH_LOGOUT]: state => {
    state.user = null;
    state.status = "";
    state.error = null;
    state.userAttributes = null;
  },
  [types.SET_USER]: state => {
    state.status = "loading";
  },
  [types.SET_USER_SUCCESS]: (state, user) => {
    state.status = "success";
    state.user = user;
    state.hasLoadedOnce = true;
  },
  [types.SET_USER_FAILURE]: (state, error) => {
    state.status = "error";
    state.error = error;
    state.hasLoadedOnce = true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
