import * as api from "../../utils/api";
import * as appMutations from "../appMutations";
import * as types from "./mutation-types";

export const actions = {
  // Templates
  [types.LOAD_TEMPLATES]: async ({ commit }, type) => {
    commit(types.LOAD_TEMPLATES, type);
    try {
      const { data } = await api.get(`admin/templates?type=${type}`);
      commit(types.LOAD_TEMPLATES_SUCCESS, data);
    } catch (error) {
      commit(types.LOAD_TEMPLATES_FAILURE, error);
      throw error;
    }
  },
  [types.LOAD_TEMPLATE_BY_ID]: async ({ commit, dispatch }, templateID) => {
    commit(types.LOAD_TEMPLATE_BY_ID, templateID);
    try {
      const { data } = await api.get(`admin/templates/${templateID}`);
      commit(types.LOAD_TEMPLATE_BY_ID_SUCCESS, data);
    } catch (error) {
      commit(types.LOAD_TEMPLATE_BY_ID_FAILURE, error);
      dispatch(appMutations.SNACKBAR_ERROR, "Error Loading Template", {
        root: true,
      });
      throw error;
    }
  },
  [types.CREATE_TEMPLATE]: async ({ commit, dispatch }, template) => {
    commit(types.CREATE_TEMPLATE, template);
    template.organization = localStorage.getItem("organizationID");
    try {
      const data = await api.post("admin/templates", template);
      commit(types.CREATE_TEMPLATE_SUCCESS, data);
      dispatch(appMutations.SNACKBAR_SUCCESS, "Template Created", {
        root: true,
      });
    } catch (error) {
      commit(types.CREATE_TEMPLATE_FAILURE, error);
      dispatch(appMutations.SNACKBAR_ERROR, "Error Creating Template", {
        root: true,
      });
      throw error;
    }
  },
  [types.UPDATE_TEMPLATE]: async ({ commit, dispatch }, template) => {
    commit(types.UPDATE_TEMPLATE, template);
    try {
      const { data } = await api.put(`admin/templates/${template.id}`, template);
      commit(types.UPDATE_TEMPLATE_SUCCESS, data);
      dispatch(appMutations.SNACKBAR_SUCCESS, "Template Updated", {
        root: true,
      });
    } catch (error) {
      commit(types.UPDATE_TEMPLATE_FAILURE, error);
      dispatch(appMutations.SNACKBAR_ERROR, "Error Updating Template", {
        root: true,
      });
      throw error;
    }
  },
  [types.DELETE_TEMPLATE]: async ({ commit, dispatch }, id) => {
    commit(types.DELETE_TEMPLATE);
    try {
      await api.deleteResource(`admin/templates/${id}`);
      commit(types.DELETE_TEMPLATE_SUCCESS, id);
      dispatch(appMutations.SNACKBAR_SUCCESS, "Template Deleted", {
        root: true,
      });
    } catch (error) {
      commit(types.DELETE_TEMPLATE_FAILURE, error);
      dispatch(appMutations.SNACKBAR_ERROR, "Error Deleting Template", {
        root: true,
      });
      throw error;
    }
  },
  [types.RESET_TEMPLATE]: ({ commit }) => {
    commit(types.RESET_TEMPLATE);
  },
};
