import * as types from "./mutation-types";
import { actions } from "./actions";

const getDefaultState = () => {
  return {
    counts: {
      unread: null,
      total: null,
    },
    messages: [],
    patientConversations: null,
    conversations: null,
    loadingConversations: false,
    newConversation: null,
    messageProcessing: false,
    notifications: [],
    templates: [],
    template: null,
    processing: false,
  };
};

const state = getDefaultState();

const getters = {
  getMessageCount: state => state.counts?.total,
  getUnreadMessageCount: (state, getters, rootState) => {
    return state.counts?.unread;
  },
  getActiveTemplates: state => {
    return state.templates?.filter(t => t.active);
  },
};

export const mutations = {
  [types.RESET]: state => {
    Object.assign(state, getDefaultState());
  },
  [types.LOAD_COUNTS_SUCCESS]: (state, counts) => {
    state.counts = counts;
  },
  [types.LOAD_CONVERSATIONS]: state => {
    state.loadingConversations = true;
    state.error = null;
  },
  [types.LOAD_CONVERSATIONS_SUCCESS]: (state, conversations) => {
    state.conversations = conversations;
    state.loadingConversations = false;
  },
  [types.LOAD_CONVERSATIONS_FAILURE]: (state, error) => {
    state.error = error;
    state.loadingConversations = false;
  },
  [types.LOAD_PATIENT_CONVERSATIONS]: state => {
    state.patientConversations = null;
    state.error = null;
  },
  [types.LOAD_PATIENT_CONVERSATIONS_SUCCESS]: (state, conversations) => {
    state.patientConversations = conversations;
  },
  [types.LOAD_PATIENT_CONVERSATIONS_FAILURE]: (state, error) => {
    state.error = error;
  },
  [types.CREATE_CONVERSATION]: state => { },
  [types.CREATE_CONVERSATION_SUCCESS]: (state, conversation) => {
    state.newConversation = conversation;
  },
  [types.CREATE_CONVERSATION_FAILURE]: (state, error) => {
    state.error = error;
  },
  [types.UPDATE_CONVERSATION]: state => { },
  [types.UPDATE_CONVERSATION_SUCCESS]: (state, conversation) => {
    state.conversations?.splice(
      state.conversations?.findIndex(c => c.guid === conversation.guid),
      1,
      conversation
    );
  },
  [types.UPDATE_CONVERSATION_FAILURE]: (state, error) => {
    state.error = error;
  },
  [types.CREATE_MESSAGE]: state => {
    state.messageProcessing = true;
  },
  [types.CREATE_MESSAGE_SUCCESS]: state => {
    state.messageProcessing = false;
    state.loadingConversations = false;
  },
  [types.CREATE_MESSAGE_FAILURE]: (state, error) => {
    state.error = error;
    state.messageProcessing = false;
  },
  [types.DELETE_CONVERSATION]: state => { },
  [types.DELETE_CONVERSATION_SUCCESS]: state => { },
  [types.DELETE_CONVERSATION_FAILURE]: (state, error) => {
    state.error = error;
  },
  [types.LOAD_MESSAGE_TEMPLATES]: state => { },
  [types.LOAD_MESSAGE_TEMPLATES_SUCCESS]: (state, templates) => {
    state.templates = templates.map(t => {
      return { ...t, icon: t.icon ?? t.type == "SMS" ? "mdi-message-processing" : "mdi-email" };
    });
  },
  [types.LOAD_MESSAGE_TEMPLATES_FAILURE]: (state, error) => {
    state.error = error;
  },
  [types.LOAD_MESSAGE_TEMPLATE]: () => { },
  [types.LOAD_MESSAGE_TEMPLATE_SUCCESS]: (state, template) => {
    state.template = template;
  },
  [types.LOAD_MESSAGE_TEMPLATE_FAILURE]: (state, error) => {
    state.error = error;
  },
  [types.CREATE_MESSAGE_TEMPLATE]: state => {
    state.processing = true;
  },
  [types.CREATE_MESSAGE_TEMPLATE_SUCCESS]: (state, template) => {
    state.template = template;
    state.processing = false;
  },
  [types.CREATE_MESSAGE_TEMPLATE_FAILURE]: (state, error) => {
    state.error = error;
    state.processing = false;
  },
  [types.UPDATE_MESSAGE_TEMPLATE]: state => {
    state.processing = true;
  },
  [types.UPDATE_MESSAGE_TEMPLATE_SUCCESS]: (state, template) => {
    state.template = template;
    state.templates.splice(
      state.templates.findIndex(t => t.id === template.id),
      1,
      template
    );
    state.processing = false;
  },
  [types.UPDATE_MESSAGE_TEMPLATE_FAILURE]: (state, error) => {
    state.error = error;
    state.processing = false;
  },
  [types.DELETE_MESSAGE_TEMPLATE]: state => {
    state.processing = true;
  },
  [types.DELETE_MESSAGE_TEMPLATE_SUCCESS]: (state, template) => {
    state.templates = state.templates.filter(t => t !== template.id);
    state.processing = false;
  },
  [types.DELETE_MESSAGE_TEMPLATE_FAILURE]: (state, error) => {
    state.error = error;
    state.processing = false;
  },
  [types.RESET_TEMPLATE]: state => {
    state.template = { type: "Email", organization: localStorage.getItem("organizationID") };
    state.processing = false;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
