import * as types from "./mutation-types";
import router from "../../router";
import * as appMutations from "../appMutations";
import * as api from "../../utils/api";
import { debounce } from "../../utils/helpers";

export const actions = {
  [types.LOAD_INSTANCES]: async ({ commit, rootState, state }) => {
    if (rootState.auth.user?.groups?.length > 0 && rootState.auth.user?.groups?.some(g => g === "Orbital-Platform-Admin")) {
      commit(types.LOAD_INSTANCES);
    }

    debounce(async () => {
      const response = await api.search("admin/instance", {}, false);
      if (!response.data) throw new Error("Error Loading Instances");
      commit(types.LOAD_INSTANCES_SUCCESS, response?.data?.sort((a, b) => a.name?.localeCompare(b.name)) || []);
    })();
  },
  [types.LOAD_INSTANCE_BY_ID]: async ({ commit, dispatch }, instanceID) => {
    commit(types.LOAD_INSTANCE_BY_ID, instanceID);
    try {
      const instance = await api.get(`admin/instance/${instanceID}`);
      commit(types.LOAD_INSTANCE_BY_ID_SUCCESS, instance.data);
    } catch (error) {
      commit(types.LOAD_INSTANCE_BY_ID_FAILURE, error);
      dispatch(appMutations.SNACKBAR_ERROR, "Error Loading Data - If the problem persists please disable any ad blockers.", {
        root: true,
      });
    }
  },
  [types.UPDATE_INSTANCE]: async ({ commit, dispatch }, { instanceID, instance }) => {
    commit(types.UPDATE_INSTANCE);
    try {
      if (!instance.fhirID) {
        const org = await api.search(`organization?identifier=${instanceID}`, {});
        const fhirID = org.data?.entry?.[0]?.resource?.id;
        if (!fhirID) {
          const newOrg = await api.post("organization", {
            resourceType: "Organization",
            identifier: [
              {
                system: "fire-store",
                value: instanceID,
              },
            ],
            name: instance.name,
          });
          instance.fhirID = newOrg.id;
        } else {
          instance.fhirID = fhirID;
        }
      }
      let response = await api.put(`admin/instances/${instanceID}`, instance);
      if (!response) throw new Error("Error Saving Instance");
      commit(types.UPDATE_INSTANCE_SUCCESS, instance);
      dispatch(appMutations.SNACKBAR_SUCCESS, "Instance Saved", {
        root: true,
      });
    } catch (error) {
      commit(types.UPDATE_INSTANCE_FAILURE, error);
      dispatch(appMutations.SNACKBAR_ERROR, "Error Saving Instance", {
        root: true,
      });
    }
    return Promise.resolve();
  },
  [types.CREATE_INSTANCE]: async ({ commit, dispatch }, instance) => {
    commit(types.CREATE_INSTANCE);
    instance.organization = localStorage.getItem("organizationID");
    try {
      const result = await api.post("admin/instances", instance);
      const newOrg = await api.post("organization", {
        resourceType: "Organization",
        identifier: [
          {
            system: "fire-store",
            value: result.id,
          },
        ],
        name: result.name,
      });
      result.fhirID = newOrg.id;

      commit(types.CREATE_INSTANCE_SUCCESS, result);
      router.replace({ name: "Customize", params: { id: result.id } });
    } catch (error) {
      commit(types.CREATE_INSTANCE_FAILURE, error);
      dispatch(appMutations.SNACKBAR_ERROR, "Error Creating Instance", {
        root: true,
      });
    }
  },
  [types.RESET_INSTANCE]: ({ commit }) => {
    commit(types.RESET_INSTANCE);
  },
  [types.DELETE_INSTANCE]: async ({ commit, dispatch }, instanceID) => {
    commit(types.DELETE_INSTANCE);
    try {
      const result = await api.deleteResource(`admin/instances/${instanceID}`);
      commit(types.DELETE_INSTANCE_SUCCESS, instanceID);
      router.push({ name: "Instances" });
      dispatch(appMutations.SNACKBAR_SUCCESS, "Instance Deleted", {
        root: true,
      });
    } catch (error) {
      commit(types.DELETE_INSTANCE_FAILURE, error);
      dispatch(appMutations.SNACKBAR_ERROR, "Error Deleting Instance", {
        root: true,
      });
    }
  },
};
