import { defineStore } from 'pinia'
import * as api from "../../utils/api.js";

export const useFhir = defineStore('fhir', {
    state: () => ({
        resources: {
            PlanDefinition: [],
            Tasks: [],
            ValueSet: [],
        },
        searchResults: null,
        resourceCounts: [],
        topObservationCodes: [],
    }),
    getters: {
        activePlans(state) {
            return state.resources.PlanDefinition?.filter(p => p.status == "active").sort((a, b) => a.title.localeCompare(b.title)) ?? [];
        },
        defaultPlan(state) {
            return state.resources.PlanDefinition.filter(p => p.usage == "Default")?.[0] ?? state.resources.PlanDefinition.filter(p => p.status == "active")?.[0] ?? {
                action: [
                    {
                        title: "Action",
                        condition: [
                            {
                                kind: "applicability",
                                expression: {
                                    language: "text/cql",
                                    expression: "Task.businessStatus = `ready`"
                                }
                            }
                        ]
                    }]
            };
        },
        getPlansByType: (state) => (type) => {
            return state.resources.PlanDefinition.filter(p => p.status == "active" && p.type?.coding?.[0]?.code == type);
        },
        getResourceById: (state) => (name, id) => {
            return state.resources[name]?.find(r => r.id == id);
        },
        getResourceByIdentifier: (state) => (name, identifier) => {
            return state.resources[name]?.find(r => r.identifier?.[0]?.value == identifier);
        },
        getResourceByCode: (state) => (name, code) => {
            return state.resources[name]?.find(r => r.code == code);
        },
    },
    actions: {
        async loadResourceCounts() {
            const resourceCountResponse = await api.search("admin/resourceCounts", {});
            this.resourceCounts = resourceCountResponse?.data?.metrics ?? [];
            this.resourceCounts?.sort((a, b) => b.count - a.count);
        },
        async searchResource(name, query) {
            var response = await api.search(name, query ?? {});
            this.searchResults = response?.data ?? [];
            return response?.data;
        },
        async loadResources(name) {
            var response = await api.get(name);
            this.resources[name] = response?.data?.entry?.map(e => e.resource) ?? [];
        },
        async updateResource(name, resource) {
            var response = await api.put(name, resource);
            return response?.data;
        },
        async createResource(name, resource) {
            var response = await api.post(name, resource);
            return response?.data;
        },
        async deleteResource(name, id) {
            var response = await api.deleteResource(`${name}/${id}`);
            //remove from store
            this.resources[name] = this.resources[name].filter(r => r.id != id);
            return response?.data;
        },
        async fetchTopObservationCodes() {
            var response = await api.get("admin/report/observation-counts-by-code/json");
            this.topObservationCodes = response?.data?.rowData
        }
    },
})